table, th, td { border: 1px solid black; padding: 2px; }
h1 { padding-bottom: 0px; }
h2 { margin-top: 15px; }
a { color: #0e00ff; text-decoration: underline; }

table th { background: #DFF2BF; }
span#parcelId { font-size: larger; font-weight: 700; }

td#actions { width: 1%; }
td#actions { text-align:center; }

table#prop { width: 870px; }
table#prop td.label { width: 13%; background: #DFF2BF; }
table#prop td.data {  }
table#prop textarea { width: 100%; }
table#prop input { width: 95%; }

table#notes { width: 870px; }
table#notes th { text-align:center; }
table#notes td#date { width: 15%; }
table#notes td#note { vertical-align: text-top; }
table#notes textarea#notes_user { width: 100%; }

table#taxes { width: 870px; }
table#taxes th { text-align:center; }
table#taxes td#year { width: 10%; }
table#taxes td#value { width: 7%; text-align:right; }
table#taxes td#taxdue { width: 7%; text-align:right; }
table#taxes td#note { width: 50%; }
table#taxes textarea#notes_user { width: 100%; }

table#deeds { width: 870px; }
table#deeds th { text-align:center; }
table#deeds td#date { width: 15%; vertical-align: text-top}
table#deeds td#desc { width: 30%; }
table#deeds td#trans {  }

table#uploadForm { width: 870px; }
table#uploadForm td.label { width: 13%; background: #DFF2BF; text-align:right;}
table#uploadForm td.data {  }
table#uploadForm input#doc_title { width: 95%; }
table#uploadForm input#doc_file { width: 95%; }
table#uploadForm textarea#doc_note { width: 95%; }

table#documents { width: 870px; }
table#documents th { text-align:center; }
table#documents td#date { width: 15%; vertical-align: text-top}
table#documents td#file { width: 30%; }
table#documents input#title { width: 100%; }
table#documents textarea#note { width: 100%; }

span.modDate { font-size: smaller;}


table.proplist {
    margin: 20px 0px 10px 0px;
    padding: 0px;
    border-collapse:collapse;
    border-spacing: 0px;width: 100%;
}
table.proplist td { border: 1px solid; padding: 2px; }
table.proplist tr#property_row:nth-child(even) { background-color: #f3ffe0; }
table.proplist thead { font-weight: bold }
table.proplist td.acct_num {width: 5%;  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif; font-size: x-small}
table.proplist td.parcel_id {width: 30%; font-family: "Trebuchet MS", Arial, Helvetica, sans-serif; font-size: x-small}
table.proplist td.legal_desc {width: 45%}
table.proplist td.acreage {width: 10%; text-align: right}
table.proplist td.assessed_value {width: 10%; text-align: right}
table.proplist td.taxes {width: 10%; text-align: right}
table.proplist td.status {width: 10%; text-align: center}

@media print {
    table.proplist {page-break-inside: avoid;}
    .container {margin-left: 72px; margin-right: 72px}
    table.proplist { width: 720px; }
}

.flash-message { text-align: center; padding: 10px; background: #DFF2BF; border: 1px solid; color: #4F8A10; margin-bottom: 10px; }


.grid table
{
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    color: #555555;
    font-size: 1em;
    width: 100%;
}

.grid td, .grid th
{
    border: 1px solid #D4E0EE;
    padding: 3px 7px 2px 7px;
}
.grid th
{
    background-color: #E6EDF5;
    vertical-align: top;
}

.grid th a {
    color: #4F76A3;
    text-decoration: none;
}

.grid th a.grid-reset {
    margin-left: 5px;
    font-weight: normal;
}

.grid tr.even
{
    background-color: #FCFDFE;
}

.grid tr.odd {
    background-color: #F7F9FC;

}

.grid_header, .grid_footer {
    margin: 5px 0;
}

/* Icons for order */
/* You can find this icons in the images directory of the docuementation */
th div {
    height: 10px;
    width: 20px;
    float: right;
    padding-top: 4px;
}

.grid th div.sort_up {
    background: transparent url("data:image/gif;base64,R0lGODlhFwAKAIABAJCQkO/v7yH+EUNyZWF0ZWQgd2l0aCBHSU1QACH5BAEKAAEALAAAAAAXAAoAAAIajI+py+0GwGsxTmVDvlqe/YCQ52wmyaXqUQAAOw==") no-repeat bottom left;
}

.grid th div.sort_down {
    background: transparent url("data:image/gif;base64,R0lGODlhFwAKAHAAACH5BAEAAAIALAAAAAAXAAoAgQAAAJCQkAAAAAAAAAIalI+py60RDpTRiZmwvdXozXkdKH6keKZqUwAAOw==") no-repeat bottom left;
}

/* Boolean column */
.grid .grid_boolean_true {
    background: transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACl0lEQVR42q2T60uTYRiH/Tv2bnttAwlkRCGChFD7FCQSm2ZDMQ/L0nRnj7TNGDbTooychzFSSssstdqc8zB1anNrSpm47FVCzH3pQLVhdLBfzztoJlifvOEHz4fnuu7nGBe311XgOyLMnTmsz/akMBljB8OSEVFY4kpkJM5Efbp9v/C/cJ43VSrzJId0HhluBy3oW+mKpnOpGSWuExD30iFxDy3dFSZdpZkTSZHr80Y41/phe3UDpvnKaNixY60PjbNVOGTjRZJtvJ2SHE+KINOdtMHC7MSaQBkq/CXQzJ6DjqScpNp3HvY3D3B5ugIiC3dDdJMriAlk7iSDajwr2pmFWVDlPQPFTCEU0wVQTxfCvT4Ig1cJB5Hk9hxDwjWuISbIGBExncFmWINNqPAVQ/lUTsB8KKdIPPmYeOsCW6HIOtpeNMI234j4ei4TExy3J2w+Wr2L2oAGWm8RWckAlj4uQDVZiPH1oSj8c+sH2p5fgWGyGH3BTvCN1GZMIH5Ib/avdMPoV6HWr8Xnb5+i0Iev72KwZa4ealc29O6z6A92gF/zt6CHZm4tNKF98Sp0U3KYfdWIfP8Shbd+bcHy7BLKnFnQEEFLoA7tXjPoKmp7C6l3+Ab5QBrsq/dRPSmH2n0adTPlWH6/iLa5BpQOnoTCcQo6Zw7sr7uRbj0KupLaPsRkK09wgFyN2aPBY+YeKkfzoB3OgWpIBqWDDQtn48lyF4xDxeCrORu0mhLseAuJTVxpfAMVMbnL4CCS1oAZ+tEiXBiWo5VswU5gvbMIvFJOhMC7v8Z9DVwpbaJCkg4x2v1m9L60onfBCovXhLSWVPAVnBCt+gf8p+iLXCFtoPR0DcXwtZwwX8UJk44MiZ4upYR7/nt/A+w9sdKFchsrAAAAAElFTkSuQmCC") no-repeat bottom left;
    display: inline-block;
    text-indent: 16px;
    width: 16px;
    overflow: hidden;
}

.grid .grid_boolean_false {
    background: transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACSElEQVR42q2Ty0vUURTHv/5SJ1SmUaGckUYdG5FyVdAicGMkFAXBtEl8gJseIP0FEVEtbNNiFm0iMMUgGKhVIUj0oqKFQ40N2TwwdewxOik6zcy953tbmD/HxFV9d/dwz+f7PXAO8I8q+bugb1xspjFdhuwlWUcSJL+SvEfhaPXgcHxbQOH6hYChCa6WlLvLm/eh1OkEAKjlJWSjUVjZpXlSBvbcehDaAshfOx8w5FB+t7eyosUPSU2DqWkAgOX2wvI0YPljFLmpD6sU6fPefhSyAbmr53wkXxTqvJ7KlhYUXo0BACou3wEArFzpB2hQ3t6JpcgkVqITKSHb/XefJCwAENHdWcux0WwMDI09GjUh2iA7/hjO1v0wVbUerVQ3AFgAoLXucfj9kNkkDA0oBkZzE4BKIAUin4hjV2sblFI9xYD6MpcLeiYJo2k7rksKhKi1ei4WR5mrBlrp+g2A0jBi7MZ1RzUV2RhB/YEIASG0VihKoOby6UVY7gY7qiji18txrDy8b7tTEaV7G5FNp6GVnitOMPwz8h47PI32Z1GEs/8Sqk6fBRUhmhAhHI0+fA+/g9Z62AYopUYKC6lUZjKCio7jNmQxeBMLwUGIrL1dJ07iRySMzMznlNZ6ZNMiTXZ3BCgy5DpwuLK6tQ25ZAK5WBzGGDiafHA0+ZCOhPHl9dgqyb6jTz+FtqzyxJkjASGDO2s87tq2g3C4qmEMkMss4tvEW2RmY/MkB449mwpte0xvTh1qJtlFYS8pm4+JHO18Hovjf+o3Xg+XX4ZLBPIAAAAASUVORK5CYII=") no-repeat bottom left;
    display: inline-block;
    text-indent: 16px;
    width: 16px;
    overflow: hidden;
}

/* Alignement */
.grid .align-left {
    text-align: left;
}

.grid .align-center {
    text-align: center;
}

.grid .align-right {
    text-align: right;
}

/* Column filter */
.grid .grid-filter-operator select{
    width: 70px;

}

.grid .grid-filter-input-query input, .grid .grid-filter-select-query select{
    width: 50px;
}

.grid .grid-filter-input-query-to, .grid .grid-filter-select-query-to{
    margin-left: 77px;
    display: block;
}

/* Grid Search */

.grid-search {
    border: 1px solid #D4E0EE;
    padding: 10px;
}

.grid-search label{
    width: 80px;
    display: inline-block;
    text-align: right;
}

.grid-search select, .grid-search .grid-filter-input-query input {
    width: 150px;
}
