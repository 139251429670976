@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~bootstrap';
/*@import '~@fortawesome/fontawesome-free/css/all.css';*/
@import 'dashboard.css';
@import 'Property.css';
@import 'styles.css';
@import 'login.css';

body {
}

.search-preview {
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 44px;
}
